import { FunctionComponent, SVGProps } from 'react';

export const ClipboarListLightIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            width="16"
            height="16"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M401 438.6l-49.19-30.75C409.88 367.39 448 300.19 448 224 448 100.29 347.71 0 224 0S0 100.29 0 224c0 76.19 38.12 143.39 96.23 183.85L47 438.6a32 32 0 0 0-15 27.14V480a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32v-14.26a32 32 0 0 0-15-27.14zM32 224c0-106 86-192 192-192s192 86 192 192-86 192-192 192S32 330 32 224zm352 256H64v-14.26L127.62 426a221.84 221.84 0 0 0 192.76 0L384 465.74zm0-256a160 160 0 1 0-160 160 160 160 0 0 0 160-160zm-288 0a128 128 0 1 1 128 128A128.14 128.14 0 0 1 96 224zm144-80a16 16 0 0 0-16-16 96.1 96.1 0 0 0-96 96 16 16 0 0 0 32 0 64.07 64.07 0 0 1 64-64 16 16 0 0 0 16-16z" />
        </svg>
    );
};

export default ClipboarListLightIcon;
