import { FunctionComponent, SVGProps } from 'react';

export const LaptopRegularIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="clock"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            {...props}
        >
            <path d="M616 352H24C10.75 352 0 362.8 0 376v16C0 440.5 39.47 480 88 480h464c48.53 0 88-39.47 88-88v-16C640 362.8 629.3 352 616 352zM552 432H88c-19.31 0-35.48-13.77-39.2-32h542.4C587.5 418.2 571.3 432 552 432zM112 88c0-4.406 3.594-8 8-8h400c4.406 0 8 3.594 8 8V320H576V88C576 57.13 550.9 32 520 32H120C89.13 32 64 57.13 64 88V320h48V88z" />
        </svg>
    );
};

export default LaptopRegularIcon;
