import { FunctionComponent, SVGProps } from 'react';

export const CameraWebRegularIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="camera-web"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            {...props}
        >
            <path d="M224 96C157.7 96 104 149.7 104 216c0 66.27 53.73 120 120 120c66.28 0 120-53.73 120-120C344 149.7 290.3 96 224 96zM224 288C184.3 288 152 255.7 152 216S184.2 144 223.9 144S296 176.3 296 216S263.7 288 224 288zM347.8 392.9C403.5 353.8 440 289.2 440 216c0-119.3-96.71-215.1-216-215.1c-119.3 0-216 96.71-216 215.1c0 73.22 36.52 137.8 92.25 176.9l-63.13 80.29c-5.656 7.219-6.719 17.05-2.719 25.31C38.44 506.8 46.81 512 56 512h336c9.188 0 17.56-5.25 21.59-13.52c4-8.266 2.938-18.09-2.719-25.31L347.8 392.9zM105.4 464l37.61-47.84C168 426.3 195.3 432 224 432s55.96-5.705 80.98-15.84L342.6 464H105.4zM224 384c-92.63 0-168-75.37-168-168C56 123.4 131.4 48 224 48c92.64 0 168 75.36 168 168C392 308.6 316.6 384 224 384z" />
        </svg>
    );
};

export default CameraWebRegularIcon;
