import { FunctionComponent, SVGProps } from 'react';

export const CameraHomeRegularIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="camera-home"
            role="img"
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            {...props}
        >
            <path d="M384 48c8.8 0 16 7.2 16 16V352c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H384zM64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h40.1L67.6 475.4c-4.6 7.4-4.8 16.7-.5 24.3s12.3 12.3 21 12.3H360c8.7 0 16.7-4.7 21-12.3s4-16.9-.5-24.3L343.9 416H384c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM287.5 416l29.5 48H130.9l29.5-48h127zM336 208a112 112 0 1 0 -224 0 112 112 0 1 0 224 0zM224 144a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
        </svg>
    );
};

export default CameraHomeRegularIcon;
